<template>
    <r-card :class="`${$r.prefix}table-manage`">
        <r-modal bottom full-width v-model="showForm">
            <slot name="form" :autoSend="autoSend"
                  :method="method"
                  :options="table.option"
                  :title="title"
                  :url="url"
                  :modelValue="editedItem"
                  :ok="ok">
                <r-form-creator :autoSend="autoSend"
                                :method="method"
                                :options="table.option"
                                :title="title"
                                :url="url"
                                :modelValue="editedItem"
                                @ok="ok"
                ></r-form-creator>
            </slot>
        </r-modal>
        <manage-header v-model="search" @update:modelValue="searching()" :header-table="table.headers"
                       :advance-search="advanceSearch" :disable-add="disableAdd" :new-item="newItem"
                       :loading="loading" @a-search="(a_search=$event),(searching())"></manage-header>
        <r-table :responsive="responsive" transition="table-row" :headers="headerTable" :items="table.data"
                 key-item="_id">
            <template v-slot:header="{header}">
                <th :class="{
              'header-sortable':$helper.ifHas(item, true, 'option', 'sortable')
              }"
                    :key="`th-${key}`"
                    @click.stop="sortSetup(item)"
                    v-for="(item,key) in header">
                    <r-row class="no-gutters v-end">
                        <r-col class="col-11"><span>{{item.text}}</span></r-col>
                        <r-col class="col-1">
                            <div :class="{
            'icon-hidden': !(sortBy === item.value && sortType !== 0)
            }"
                                 class="icon-holder">
                                <r-icon :class="{
            'mdi-arrow-down': (sortType === 2 && sortBy === item.value),
        'mdi-arrow-up': (sortType !== 2 || sortBy !== item.value)
            }"></r-icon>
                            </div>
                        </r-col>

                    </r-row>
                </th>
            </template>

            <template v-slot:row="props">
                <slot name="row" :table="props" :editItem="editItem" :deleteItem="deleteItem">
                    <td :key="`td-${key2}`" v-for="(value,key2) in props.th">
                        <r-btn @click.prevent="props.open(props.key)"
                               icon text v-if="props.show(key2)">
                            <r-icon :class="{
                                                'mdi-plus':props.opened!==props.key,
                                                'mdi-minus':props.opened===props.key,
                                            }"></r-icon>
                        </r-btn>
                        <slot name="cell" :value="value" :item="props.item" :editItem="editItem">
                        <div v-if="value['option']['type']==='date-picker' && props.item[value['value']]!==undefined">
                            {{$d(new Date(props.item[value['value']]),value['option']['format']||'short')}}
                        </div>
                        <div v-else-if="value['option']['type']==='time-ago' && props.item[value['value']]!==undefined">
                            <r-time-ago :time="props.item[value['value']]"></r-time-ago>
                        </div>
                        <div v-else-if="value['option']['type']==='switch'">
                            <r-switch
                                    :readonly="value['option']['formInput']===false"
                                    :modelValue="props.item[value['value']]"
                                    @update:modelValue="value['option']['formInput']!==false?editItem(props.item,true,value['value']):''"
                                    class="mt-0"
                            ></r-switch>
                        </div>
                        <div v-else-if="value['option']['type'] === 'number'">
                            {{ $n(props.item[value["value"]]) }}
                        </div>
                        <div v-else-if="value['option']['type']!=='action'">
                                {{value['value'] in cast?
                                $helper.ifHas(props.item,'',value['value'],cast[value['value']])
                                :props.item[value['value']]}}
                        </div>
                        </slot>
                        <div v-if="value['option']['type']==='action'">
                            <r-btn v-if="!disableUpdate" @click.prevent="editItem(props.item)"
                                   class="mx-0 color-success-text" icon text>
                                <r-icon class=" mdi-pen exact-icon"></r-icon>
                            </r-btn>
                            <r-btn v-if="!disableDelete" @click.prevent="deleteItem(props.item)"
                                   class="mx-0 color-error-text" icon text>
                                <r-icon class=" mdi-delete"></r-icon>
                            </r-btn>
                            <r-btn :key="index" @click.prevent="$emit(val.name,props.item)" class="mx-0" icon text
                                   v-for="(val,index) in actions" :class="`color-${val.color}-text`">
                                <r-icon :class="` ${val.icon}`"></r-icon>
                            </r-btn>
                        </div>
                    </td>
                </slot>
            </template>

        </r-table>
        <manage-footer v-model:page="page" v-model:per-page="itemsPerPage" :total="table.total"></manage-footer>
        <r-confirm
                hard
                v-model="showConfirm"
                v-on:accept="accept"
                v-on:cancel="showConfirm = false"
        />
    </r-card>
</template>

<script>

    import ManageFooter from "./footer";
    import ManageHeader from "./header";

    export default {
        name: 'r-table-crud',
        components: {ManageHeader, ManageFooter},
        props: {
            link: {
                required: true,
                type: String
            },
            actions: {
                default: function () {
                    return []
                },
                type: Array
            },
            cast: {
                default: function () {
                    return {}
                },
                type: Object
            },
            perPage: {
                type: Object, default: () => {
                    return {name: '10', value: 10}
                }
            },
            query: String,
            responsive: {
                type: Boolean,
                default: true
            },
            reGet: Boolean,
            disableAdd: Boolean,
            advanceSearch: {type: Boolean, default: true},
            disableDelete: Boolean,
            disableUpdate: Boolean
        },
        data() {
            return {
                time_out_id: null,
                loading: false,
                showForm: false,
                showConfirm: false,
                search: '',
                a_search: {},
                editedItem: {},
                deleted: '',
                url: '',
                method: 'post',
                title: '',
                autoSend: false,
                itemsPerPage: this.perPage,
                page: 1,
                sortBy: null,
                sortType: 0,
                table: {
                    headers: [],
                    option: {},
                    data: [],
                    startTime: false,
                    total: 0
                }
            }
        },
        created() {
            this.refresh()
        },
        watch: {
            page: function (n, o) {
                if (n > 0) {
                    this.refresh()
                }
            }, itemsPerPage: function () {
                this.refresh()
            },
            reGet: function (newVal) {
                if (newVal) {
                    this.refresh()
                }
            }
        },
        computed: {
            headerTable() {
                const headers = this.table.headers
                const res = []
                for (let i = 0; i < headers.length; i++) {
                    if (this.$helper.ifHas(headers[i], true, 'option', 'tableShow') !== false) {
                        res.push(headers[i])
                    }
                }
                return res
            }
        },
        methods: {
            sortSetup(item) {
                if (!this.$helper.ifHas(item, true, 'option', 'sortable')) {
                    return
                }
                if (this.sortBy !== item.value) {
                    this.sortType = 0
                }
                this.sortBy = item.value
                if (this.sortType >= 2) {
                    this.sortType = 0
                } else {
                    this.sortType += 1
                }
                this.refresh()
            },
            ok() {
                this.refresh()
                this.autoSend = false
                this.showForm = false
            },
            refresh(e) {
                this.loading = true
                const perPage = e !== undefined ? e.value : this.itemsPerPage.value
                let params = '?page=' + this.page
                if (this.table.startTime !== false) {
                    params += '&t=' + this.table.startTime
                }
                if (this.sortType !== 0) {
                    const sort = (this.sortType === 2) ? 'desc=' : 'asc='
                    params += '&' + sort + this.sortBy
                }
                params += '&per_page=' + perPage
                if (this.search.length >= 1) {
                    params += '&search=' + this.search
                }
                if (this.$helper.size(this.a_search)>0) {
                    params += '&a_search=' + JSON.stringify(this.a_search)
                }
                if (this.query) {
                    params += '&' + this.query
                }
                this.setup('/' + this.link + params)
            },
            searching() {
                clearTimeout(this.time_out_id)
                this.loading=true
                this.time_out_id = setTimeout(() => {
                    this.page = 1
                    this.refresh()
                }, 1000)

            },
            newItem() {
                this.title = this.$t('new', 'renusify')
                this.url = this.link
                const items = {}
                this.table.headers.map((item) => {
                    if (item.option.formInput !== false) {
                        if (item.option.type === 'boolean') {
                            items[item.value] = false
                        } else {
                            items[item.value] = null
                        }
                    }
                })
                this.editedItem = items
                this.method = 'post'
                this.autoSend = false
                this.showForm = true
            },
            editItem(item, autoSend = false, key = null) {
                let sw
                this.title = this.$t('edit', 'renusify')
                this.url = this.link + '/' + item._id
                if (key) {
                    sw = !item[key]
                }
                const items = {}
                this.table.headers.map((header) => {
                    if (header.option.formInput !== false) {
                        if (header.option.type === 'boolean') {
                            items[header.value] = item[header.value] !== undefined ? item[header.value] : false
                        } else {
                            items[header.value] = item[header.value] !== undefined ? item[header.value] : null
                        }
                    }
                })
                this.editedItem = Object.assign({}, items)

                if (key) {
                    this.editedItem[key] = sw
                }
                this.method = 'put'
                this.autoSend = autoSend
                this.showForm = true
            },
            deleteItem(item) {
                this.showConfirm = true
                this.deleted = item._id
            },
            delete(_id) {
                this.$axios.delete(this.link + '/' + _id)
                    .then((res) => {
                        this.refresh()
                    })
            },
            accept() {
                this.showConfirm = false
                this.delete(this.deleted)
                this.deleted = ''
                this.refresh()
            },
            setup(url) {
                this.loading = true

                this.$axios.get(url).then((res) => {
                    this.table.headers = res.data.headers.map((item) => {
                        this.table.option[item.value] = item.option
                        item.text = this.$t(item.value)
                        return item
                    })
                    this.table.data = res.data.data
                    this.table.total = res.data.total
                    this.table.startTime = this.$helper.ifHas(res, false, 'data', 't')
                    this.loading = false
                })
            }
        }
    }
</script>
