<template>
    <div ref="map"
         :class="{[`${$r.prefix}map`]:true,'map-dark': dark, 'attribution-show': attribution }"
    >
        <div class="map-search" v-if="searchBox">
            <r-search-input
                    :label="$t('search','renusify')"
                    @select="go"
                    url="https://nominatim.openstreetmap.org/search.php?polygon_geojson=0&accept-language=fa&countrycodes=IR&format=jsonv2"
                    query="q"
                    no-overlay
                    :notFoundMsg="$t('map_not_found','renusify')"
            >
                <template v-slot="{ item }">
                    {{ item["display_name"] }}
                </template>
            </r-search-input>
        </div>
        <div class="map-box" :id="map_id" :style="`height: ${height}`"></div>

        <r-btn
                class="map-location color-white"
                icon
                @click.prevent="showConfirm"
                :loading="loading"
        >
            <r-icon class="mdi-crosshairs-gps"></r-icon>
        </r-btn>
        <r-btn
                class="map-attribution color-white"
                @click.prevent="attribution = !attribution"
                size="x-small"
                icon
        >
            <r-icon class="mdi-copyright subtitle-1" exact></r-icon>
        </r-btn>

        <r-confirm v-model="confirm"
                   @accept="getLocation"
                   @cancel="confirm = false" :text="$t('map_location_confirm','renusify')"></r-confirm>
    </div>

</template>

<script>
    export default {
        name: "rMap",
        props: {
            modelValue: Array,
            height: {type: String, default: "500px"},
            zoom: {type: Number, default: 13},
            center: {
                type: Array,
                default: () => {
                    return [37.5357, 45.0779];
                }
            },
            markers: {
                type: Array,
                default: () => {
                    return [];
                }
            },
            searchBox: Boolean,
            darkMode: Boolean
        },
        data() {
            return {
                dark: this.darkMode,
                attribution: false,
                confirm: false,
                loading: false,
                L: null,
                map: null,
                layerGroup: null,
                map_id: "map_" + this.$helper.uniqueId(12),
                layers: {},
                license: '&copy; | <a href="https://leafletjs.com" target="_blank">Leaflet</a> | <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> contributors | Renusify'
            };
        },
        async beforeMount() {
            await this.add();
        },
        watch: {
            modelValue: function () {
                this.map.flyTo(this.modelValue);
            },
            markers: function () {
                this.printMarkers()
            }
        },
        methods: {
            showConfirm() {
                if (this.$storage.has('map_location_access')) {
                    this.getLocation()
                } else {
                    this.confirm = true
                }
            },
            go(e) {
                if (e.lat) {
                    this.map.flyTo([e.lat, e.lon]);
                }
            },
            initMap() {
                let opt = {
                    maxZoom: 19,
                    zoomControl: false,
                    minZoom: 3,
                    noWrap: true
                };
                this.layers[this.$t('map_hot','renusify')] = this.L.tileLayer(
                    "https://tile-{s}.openstreetmap.fr/hot/{z}/{x}/{y}.png",
                    opt
                );
                opt.dark = true;
                this.layers[this.$t('map_hot_dark','renusify')] = this.L.tileLayer(
                    "https://tile-{s}.openstreetmap.fr/hot/{z}/{x}/{y}.png",
                    opt
                );
                opt.dark = false;
                this.layers[this.$t('map_standard','renusify')] = this.L.tileLayer(
                    "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
                    opt
                );
                opt.dark = true;
                this.layers[this.$t('map_standard_dark','renusify')] = this.L.tileLayer(
                    "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
                    opt
                );
                opt.dark = false;
                opt.subdomains = ["mt0", "mt1", "mt2", "mt3"];
                this.layers[this.$t('map_google','renusify')] = this.L.tileLayer(
                    "http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}",
                    opt
                );
                opt.dark = true;
                this.layers[this.$t('map_google_dark','renusify')] = this.L.tileLayer(
                    "http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}",
                    opt
                );
                opt.dark = false;
                this.layers[this.$t('map_satelite','renusify')] = this.L.tileLayer(
                    "http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}",
                    opt
                );

                this.layers[this.$t('map_hot','renusify')].addTo(this.map);
                this.L.control
                    .zoom({
                        zoomInTitle: this.$t("map_zoom_in",'renusify'),
                        zoomOutTitle: this.$t("map_zoom_out",'renusify')
                    })
                    .addTo(this.map);

                this.L.control.layers(this.layers).addTo(this.map);
                const that = this;
                this.map.on("baselayerchange", function (e) {
                    if (that.$helper.ifHas(e, false, "layer", "options", "dark")) {
                        that.dark = true;
                    } else {
                        that.dark = that.darkMode;
                    }
                });
                this.map.on("move", function () {
                    that.$emit("update:modelValue", Object.values(that.map.getCenter()));
                });
            },
            run() {
                this.map = this.L.map(this.map_id, {
                    center: this.center,
                    zoom: this.zoom,
                    zoomControl: false
                });
                this.initMap();
                this.layerGroup = new this.L.layerGroup().addTo(this.map);
                this.printMarkers()
                this.$emit("update:modelValue", this.center);
                this.$emit("leaflet", this.L);
                this.$emit("map", this.map);
                this.printCopy()
            },
            printCopy() {
                const attr = this.$refs.map.querySelector('.leaflet-control-attribution')
                attr.innerHTML = '&copy; | <a href="https://leafletjs.com" target="_blank">Leaflet</a> | <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> contributors | Renusify'
            },
            printMarkers() {
                if (!this.layerGroup) {
                    return
                }
                this.layerGroup.clearLayers();
                this.markers.forEach((item) => {
                    let options = {riseOnHover: true}
                    if (item.icon) {
                        options.icon = new this.L.icon(item.icon);
                    }
                    let marker = new this.L.Marker(item.loc, options);
                    marker.addTo(this.layerGroup)
                    if (item.popup) {
                        marker.bindPopup(item.popup);
                    }
                    if (item.open_popup) {
                        marker.openPopup();
                    }
                    if (item.tooltip) {
                        marker.bindTooltip(item.tooltip);
                    }
                    if (item.open_tooltip) {
                        marker.openTooltip();
                    }
                    if (item.click) {
                        marker.on('click', item.click)
                    }
                })
            },
            getLocation() {
                this.$storage.set('map_location_access', true)
                this.confirm = false
                this.loading = true;
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                        this.showPosition,
                        this.showError
                    );
                } else {
                    this.loading = false;
                    this.$toast(this.$t("map_not_support_location",'renusify'), {type: "error"});
                }
            },
            showPosition(position) {
                this.map.flyTo([position.coords.latitude, position.coords.longitude]);
                this.map.zoom = 18;
                this.loading = false;
            },
            showError(error) {
                this.loading = false;
                switch (error.code) {
                    case error.PERMISSION_DENIED:
                        this.$toast(this.$t("map_access_denied",'renusify'), {type: "error"});
                        break;
                    default:
                        this.$toast(this.$t("map_unavailable",'renusify'), {type: "error"});
                        break;
                }
            },
            async add() {
                import('./leaflet.css')
                this.L = await import('./leaflet')
                this.run();
            },
        }
    };
</script>

<style lang="scss">
    @import "~renusify/style/include";

    .#{$prefix}map {
        position: relative;

        .leaflet-popup {
            max-width: 250px;
        }

        .leaflet-popup-content {
            text-align: center;
        }

        .map-box {
            z-index: 1;
        }

        .map-search {
            width: 400px;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin-right: auto;
            margin-left: auto;
            z-index: 2;
            max-width: calc(100vw - 130px);
        }

        .map-location {
            position: absolute;
            left: 10px;
            bottom: 10px;
            z-index: 2;
        }

        &.map-dark .leaflet-tile {
            -webkit-filter: hue-rotate(180deg) invert(100%);
        }

        .map-attribution {
            position: absolute;
            right: 10px;
            bottom: 10px;
            z-index: 2;
        }

        .leaflet-control-attribution {
            transition: all 0.3s ease-in-out;
            position: absolute;
            bottom: -30px;
            right: 0;
            width: 100vw;
            direction: ltr;
        }

        &.attribution-show {
            .map-attribution {
                bottom: 20px;
            }

            .leaflet-control-attribution {
                bottom: 0;
            }
        }
    }
</style>
