<template>
    <r-input
            v-bind="$attrs"
            :model-value="is_object?modelValue.text:modelValue"
            hide>

    </r-input>
    <r-container :class="`${$r.prefix}text-editor container-fluid`">
        <r-row class="editor-header h-center">
            <r-col class="col-auto">
                <r-btn-group @update:modelValue="handle_dir" :items="['mdi-format-align-left',
            'mdi-format-align-center',
            'mdi-format-align-right'
            ]"></r-btn-group>
            </r-col>
            <r-col class="col-auto">
                <r-btn-group @update:modelValue="addCss" @open="handleOpen" is-select exact :items="menu">
                    <template v-slot:list="{item}">
                        <div class="list-title pa-2"
                             :class="'color-white-text'===item['value']?'color-black '+item['value']:item['value']"
                        >{{item['text']}}
                        </div>
                    </template>
                </r-btn-group>
            </r-col>
            <r-col class="col-auto">
                <r-btn-group exact @update:modelValue="format($event)"
                             :items="{
                    'insertOrderedList':'mdi-format-list-numbered',
                    'insertUnorderedList':'mdi-format-list-bulleted-square',
            'subscript':'mdi-format-subscript',
            'superscript':'mdi-format-superscript',
            'bold':'mdi-format-bold',
            'italic':'mdi-format-italic',
            'strikeThrough':'mdi-format-strikethrough',
            'underline':'mdi-format-underline',
            'insertHorizontalRule':'mdi-color-helper',
            'removeFormat':'mdi-format-clear'}"></r-btn-group>
            </r-col>

            <r-col class="col-auto">
                <r-btn-group @update:modelValue="handle($event)"
                             :items="{
                    'DIV':'mdi-format-paragraph',
                    'BLOCKQUOTE':'mdi-format-quote-close',
                    'PRE':'mdi-xml',
                'insertImage':'mdi-image',
                'createLink':'mdi-link',
            }"></r-btn-group>
            </r-col>
            <r-col class="col-auto">
                <r-btn-group @update:modelValue="format($event)" :items="{
                'undo':'mdi-undo',
                'redo':'mdi-redo',
            }"></r-btn-group>
            </r-col>
        </r-row>
        <r-row>
            <r-col class="col-12">
                <div :ref="'editorContent'+id"
                     class="editor-content" contenteditable="true"
                     @input="updateText()" v-html="text">
                </div>
            </r-col>
            <r-col class="col-12">
                <div class="ma-1 img-holder" v-for="(img,i) in files"
                           :key="i">
                    <r-btn icon class="color-error-text" @click="deleteImage(img,i)">
                        <r-icon class="mdi-delete"></r-icon>
                    </r-btn>
                    <r-img
                           :src="'/'+img"
                           width="120"
                           alt="img"
                           height="100"></r-img>
                </div>
            </r-col>
        </r-row>
        <r-modal :closable="false"
                 :closebtn="false"
                 v-model="show">
            <r-form v-model="valid1">
                <r-container class="sheet">
                    <r-row>
                        <r-col class="col-12 ltr">
                            <r-text-input v-model="link"
                                          :label="$t('link','renusify')"
                                          :rules="['required']"></r-text-input>
                        </r-col>
                        <r-col class="col-12">
                            <r-switch :label="$t('open_new_tab','renusify')"
                                      v-model="target"></r-switch>
                        </r-col>
                    </r-row>
                    <r-row class="h-end">
                        <r-col class="col-auto">
                            <r-btn class="color-error-text"
                                   outlined
                                   @click.prevent="show=false">{{$t('cancel','renusify')}}
                            </r-btn>
                        </r-col>
                        <r-col class="col-auto">
                            <r-btn class="color-success-text"
                                   :disabled="!valid1"
                                   outlined
                                   @click.prevent="handleForm()">{{$t('send','renusify')}}
                            </r-btn>
                        </r-col>
                    </r-row>
                </r-container>
            </r-form>
        </r-modal>
        <r-modal :closable="false"
                 :closebtn="false"
                 v-model="showImg">
            <r-form v-model="valid2">
                <r-container class="sheet">
                    <r-file-uploader v-model="image"
                                     :label="$t('image','renusify')"
                                     :size="1"
                                     :upload-link="uploadLink"
                                     accept="image/*"></r-file-uploader>
                    <r-text-input v-model="img_alt"
                                  :label="$t('img_alt','renusify')"
                                  :rules="['required']"></r-text-input>
                    <r-row class="h-end">
                        <r-col class="col-auto">
                            <r-btn class="color-error-text"
                                   outlined
                                   @click.prevent="showImg=false">{{$t('cancel','renusify')}}
                            </r-btn>
                        </r-col>
                        <r-col class="col-auto">
                            <r-btn class="color-success-text"
                                   :disabled="!valid2"
                                   outlined
                                   @click.prevent="handleImageForm()">{{$t('send','renusify')}}
                            </r-btn>
                        </r-col>
                    </r-row>
                </r-container>
            </r-form>
        </r-modal>

    </r-container>
</template>

<script>
    import './style.scss'

    export default {
        name: 'r-text-editor',
        inheritAttrs: false,
        props: {
            uploadLink: {type: String, default: '/storage'},
            modelValue: {
                type: [Object, String], default: () => {
                    return {
                        text: '',
                        files: []
                    }
                }
            }
        },
        data() {
            return {
                id: 'text_editor_' + this.$helper.uniqueId(12),
                text: this.modelValue && typeof this.modelValue === 'object' && 'text' in this.modelValue ? this.modelValue.text : this.modelValue,
                files: this.modelValue && typeof this.modelValue === 'object' && 'text' in this.modelValue ? this.modelValue.files : [],
                valid1: false,
                valid2: false,
                show: false,
                showImg: false,
                image: [],
                img_alt: null,
                target: false,
                link: null,
                preSelected: null,
                menu: {
                    'font': {
                        icon: 'mdi-format-annotation-plus',
                        items: [{text: 'HI', value: 'display-1'},
                            {text: 'HI', value: 'display-2'},
                            {text: 'HI', value: 'display-3'},
                            {text: 'HI', value: 'display-4'},
                            {text: 'HI', value: 'display-5'},
                            {text: 'HI', value: 'title'},
                            {text: 'HI', value: 'subtitle-1'},
                            {text: 'HI', value: 'subtitle-2'},
                            {text: 'HI', value: 'overline'},
                            {text: 'HI', value: 'body-2'},
                            {text: 'HI', value: 'body-1'},
                            {text: 'HI', value: 'caption'},
                        ]
                    },
                    'header': {
                        icon: 'mdi-format-header-pound',
                        items: [
                            {text: 'H1', value: 'H1'},
                            {text: 'H2', value: 'H2'},
                            {text: 'H3', value: 'H3'},
                            {text: 'H4', value: 'H4'},
                            {text: 'H5', value: 'H5'},
                            {text: 'H6', value: 'H6'}
                        ]
                    },
                    'color': {
                        icon: 'mdi-format-color-fill',
                        items: [
                            {text: 'color', value: 'color-primary-text'},
                            {text: 'color', value: 'color-secondary-text'},
                            {text: 'color', value: 'color-disabled-text'},
                            {text: 'color', value: 'color-one-text'},
                            {text: 'color', value: 'color-two-text'},
                            {text: 'color', value: 'color-three-text'},
                            {text: 'color', value: 'color-error-text'},
                            {text: 'color', value: 'color-info-text'},
                            {text: 'color', value: 'color-warning-text'},
                            {text: 'color', value: 'color-success-text'},
                            {text: 'color', value: 'color-white-text'},
                            {text: 'color', value: 'color-black-text'}
                        ]
                    },
                    'background': {
                        icon: 'mdi-format-paint',
                        items: [
                            {text: 'color', value: 'color-one'},
                            {text: 'color', value: 'color-two'},
                            {text: 'color', value: 'color-three'},
                            {text: 'color', value: 'color-error'},
                            {text: 'color', value: 'color-info'},
                            {text: 'color', value: 'color-warning'},
                            {text: 'color', value: 'color-success'},
                            {text: 'color', value: 'color-black'},
                            {text: 'color', value: 'color-white'},
                        ]
                    }
                }
            }
        },
        mounted() {
            /* document.execCommand('enableObjectResizing', false, true);
            document.execCommand('enableInlineTableEditing', false, true);
            document.execCommand('enableAbsolutePositionEditor', false, true) */

            this.format('defaultParagraphSeparator', 'div')
            this.element.addEventListener('paste', function (e) {
                e.preventDefault()
                var text = (e.originalEvent || e).clipboardData.getData('text/plain')

                document.execCommand('insertHTML', false, text)
            })
        },
        beforeUnmount() {
            this.element.removeEventListener('paste', () => {
            })
        },
        computed: {
            element() {
                return this.$refs['editorContent' + this.id]
            },
            is_object(){
                return this.modelValue && typeof this.modelValue === 'object' && 'text' in this.modelValue;

            }

        },
        methods: {
            deleteImage(img,i) {
                this.$axios.delete(this.uploadLink,
                    {
                        data: {link: img}
                    }
                ).then(()=>{
                    this.files.splice(i,1)
                    this.updateText()
                })

            },
            handleImageForm() {
                if (!this.getSelection() || !this.image || !this.img_alt) {
                    this.$toast(this.$t('invalid_data', 'renusify'), {type: 'error'})
                    return
                }
                let sel = this.getSelection()
                sel.removeAllRanges()
                sel.addRange(this.preSelected)
                let url = '<img src="/' + this.image[0] + '" alt="' + this.img_alt + '"/>'
                this.files.push(this.image[0])
                document.execCommand('insertHTML', true, url)
                this.showImg = false
            },
            handleForm() {
                if (!this.getSelection() || !this.link) {
                    this.$toast(this.$t('invalid_data', 'renusify'), {type: 'error'})
                    return
                }
                let sel = this.getSelection()
                sel.removeAllRanges()
                sel.addRange(this.preSelected)
                let url = '<a href="' + this.link.trim() + '"'
                if (this.target) {
                    url += 'target="_blank"'
                }
                if (this.link.startsWith('#')) {
                    url += `id="${this.link.replace('#', '')}"`
                }
                url += '>' + sel + '</a>'

                document.execCommand('insertHTML', true, url)
                this.show = false
            },
            getSelection() {
                if (window.getSelection) {
                    return window.getSelection()
                }
                return false
            },
            handleOpen(e) {
                if (e === true) {
                    let sel = window.getSelection()
                    if (sel.rangeCount > 0) {
                        this.preSelected = sel.getRangeAt(0)
                    }
                }
            },
            addCss(e) {
                let btn = e['menu']
                e = e['item'].value
                if (!this.getSelection()) {
                    return
                }
                let sel = this.getSelection()
                sel.removeAllRanges()
                sel.addRange(this.preSelected)
                if (btn === 'header') {
                    document.execCommand('insertHTML', true, '<' + e.trim() + '>' + sel + '</' + e.trim() + '>')
                    return null
                }
                let selectedElement = sel.focusNode.parentNode
                if (selectedElement.tagName === 'SPAN' && selectedElement.innerText === sel.toString()) {
                    let cls = selectedElement.classList.value.split(' ')
                    for (let c of cls) {
                        if (this.menu[btn]['items'].includes(c.trim())) {
                            selectedElement.classList.remove(c.trim())
                        }
                    }

                    selectedElement.classList.add(e)
                } else {
                    let cls = ''
                    if (selectedElement.tagName === 'SPAN') {
                        cls = selectedElement.classList.value
                    }
                    e = cls + ' ' + e

                    document.execCommand('insertHTML', true, "<span class='" + e.trim() + "'>" + sel + '</span>')
                }
            },
            handle(e) {
                let selectedElement = window.getSelection().focusNode.parentNode
                if (selectedElement.tagName === e) {
                    this.format('formatBlock', '<div>')
                } else {
                    if (e === 'createLink') {
                        this.link = null
                        this.target = false
                        this.handleOpen(true)
                        this.show = true
                    } else if (e === 'insertImage') {
                        this.image = []
                        this.img_alt = null
                        this.handleOpen(true)
                        this.showImg = true
                    } else {
                        this.format('formatBlock', e)
                    }
                }
            },
            handle_dir(e) {
                let name = ''
                switch (e) {
                    case 0:
                        name = 'text-start'
                        break
                    case 1:
                        name = 'text-center'
                        break
                    case 2:
                        name = 'text-end'
                        break
                }
                let selectedElement = window.getSelection().focusNode
                if (selectedElement === null) {
                    return null
                }
                selectedElement = selectedElement.parentNode
                selectedElement.classList.remove('text-start', 'text-center', 'text-end')
                selectedElement.classList.add(name)
                this.updateText()
            },
            updateText() {
                this.$emit('update:modelValue', {
                    text: this.element.innerHTML,
                    files: this.files
                })
            },
            format(command, value) {
                if (command === 'removeFormat') {
                    document.execCommand('insertHTML', true, '<div>' + this.getSelection().toString() + '</div>')
                } else {
                    document.execCommand(command, false, value)
                }
            },
        }
    }
</script>
