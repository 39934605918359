<template>
    <r-input :modelValue="lazyValue" hide>
        <template v-slot="{isRequired}">
            <div :class="classes">
            <span class="checkbox-input" :class="{
                   'br-circle':rounded,
                   'checkbox-select':modelValue
               }" @click="emit">
                   <transition name="scale">
                        <r-icon v-if="modelValue" class="mdi-check color-white-text" exact></r-icon>
                   </transition>
            </span>
                <span class="ms-2 color-primary-text" @click="emit">
                <span class="color-error-text" v-if="isRequired">*</span>
                {{label}}
            </span>
            </div>
        </template>
    </r-input>
</template>
<script>
    export default {
        name: 'r-checkbox',
        props: {
            label: String,
            readonly: Boolean,
            rounded: Boolean,
            modelValue: Boolean
        },

        data() {
            return {
                lazyValue: this.modelValue
            }
        },
        watch: {
            modelValue() {
                this.lazyValue = this.modelValue
            }
        },
        computed: {
            classes() {
                let a = {'checkbox-readonly': this.readonly, 'd-flex v-end': true}
                a[`${this.$r.prefix}checkbox`] = true
                return a
            }
        },
        methods: {
            emit() {
                if (this.readonly) {
                    return
                }

                this.lazyValue = (!this.lazyValue) ? !this.lazyValue : undefined
                this.$emit('update:modelValue', this.lazyValue)
            }
        }
    }

</script>
<style lang="scss">
    @import "../../style/include";

    .#{$prefix}checkbox {
        width: 100%;
        cursor: pointer;
        @include light() {
            .checkbox-input {
                border: 1px solid var(--color-border-light)
            }
        }
        @include dark() {
            .checkbox-input {
                border: 1px solid var(--color-border-dark)
            }
        }

        .#{$prefix}icon {
            width: 20px;
            height: 20px;
        }

        .checkbox-input {
            text-align: center;
            width: 25px;
            height: 25px;
            border-radius: 4px;
            transition: .3s all ease-in-out;
        }

        &.checkbox-readonly {
            pointer-events: none;
        }

        .checkbox-select {
            background-color: currentColor;
        }
    }
</style>