<template>
    <r-input :class="`${$r.prefix}file-uploader`" :model-value="filesLinks.length>0?filesLinks:null" hide labelControlClass="label-active">
        <r-row>
            <template :key="meta?file['url']:file" v-for="(file,n) in filesLinks">
                <single class="ma-1"
                        :accept="accept"
                        :meta="meta"
                        :uploadLink="uploadLink"
                        :modelValue="file"
                        :w-p-h="wPH"
                        :maxFileSize="maxFileSize"
                        @select="$emit('add', true)"
                        @file-link="setLink(n,$event)"
                ></single>
            </template>
            <single :key="'add-'+keyAdd" v-if="filesLinks.length<size" class="ma-1"
                    :accept="accept"
                    :meta="meta"
                    :w-p-h="wPH"
                    :maxFileSize="maxFileSize"
                    :uploadLink="uploadLink"
                    :modelValue="null"
                     @select="$emit('add', true)"
                    @file-link="setLink(filesLinks.length,$event)"
            ></single>
        </r-row>
    </r-input>
</template>

<script>
    import single from './single'

    export default {
        name: 'r-file-uploader',
        props: {
            uploadLink: {
                type: String,
                required: true
            },
            accept: {
                default: '*',
                type: String
            },
            size: {
                default: 3,
                type: Number
            },
            wPH:[Number,String, Array],
            maxFileSize:Number,
            modelValue: {
                default: () => {
                    return []
                },
                type: Array
            },
            meta: Array
        },
        components: {
            single
        },
        data() {
            return {
                keyAdd: 0,
                filesLinks: [],
            }
        },
        created() {
            this.setValue()
        },
        watch: {
            'modelValue': function () {
                this.setValue()
            }
        },
        methods: {
            setValue() {
                if (this.modelValue) {
                    this.filesLinks = this.modelValue
                }
            },
            setLink(n, $event) {
                this.filesLinks[n] = $event
                let clean = this.$helper.cleanArray(this.filesLinks)
                this.filesLinks = clean
                this.$emit('update:modelValue', clean)
                this.keyAdd++
            }
        }
    }
</script>

<style lang="scss">
    @import "../../../style/include";
</style>
