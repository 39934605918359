<template>
    <div ref="uploader" class="image-card elevation-lg br-md">
        <div class="file-holder text-center">
            <div class="image-canvas" v-if="file||modelValue">
                <r-btn icon class="image-close color-white">
                    <r-icon @click.prevent="fileDelete()" class=" mdi-close color-error-text "></r-icon>

                </r-btn>
                <r-progress-circular
                        :indeterminate=false
                        :modelValue="uploadPercentage"
                        :width="2"
                        class="image-progress"
                        size="50"
                >
                    {{ `% ${uploadPercentage}` }}
                </r-progress-circular>
                <r-btn @click.prevent="$helper.copy(fileLink)" class="image-copy" icon>
                    <r-icon class="mdi-content-copy"></r-icon>
                </r-btn>
                <r-btn :href="'/'+fileLink" target="_blank" class="image-eye" icon>
                    <r-icon class="mdi-eye"></r-icon>
                </r-btn>
                <img v-if="isImg()" :class="`image ${imageStatus} `" :src="getUrl(file)">
                <r-icon v-else
                        class="mdi-file display-2 file" :class="{'color-two-text':uploadPercentage===100}"
                ></r-icon>
            </div>
            <div class="file-meta pa-1" v-if="meta && uploadPercentage===100">
                <r-text-input :label="$t(m,'renusify')" v-model="metaList[m]" @update:model-value="emit"
                              v-for="(m,k) in meta" :key="k"
                              :rules="['required']"></r-text-input>
            </div>
        </div>
        <r-icon @click.prevent.stop="pickFile" class="image-icon color-two-text mdi-plus-circle-outline display-2"
                v-if="showAdd"></r-icon>
        <input :accept="accept"
               @change="addFile()"
               ref="file"
               style="display: none"
               type="file"
               v-if="showFile"
        >
    </div>
</template>

<script>
    import RBtn from "../../button/index";
    import file from "./file";

    export default {
        name: 'inputFile',
        components: {RBtn},
        mixins:[file],
        props: {
            accept: {
                default: '*',
                type: String
            },
            wPH: [Number,String, Array],
            maxFileSize: Number,
            uploadLink: {
                type: String,
                required: true
            },
            modelValue: {
                default: null,
                type: [String, Object]
            },
            meta: {
                type: Array,
                default: null
            }
        },
        data() {
            return {
                showAdd: true,
                showFile: true,
                metaList: {}
            }
        },
        created() {
            this.setValue()
        },
        methods: {
            setValue() {
                if (this.modelValue) {
                    this.fileLink = this.meta ? this.modelValue['url'] : this.modelValue
                    this.metaList = this.meta ? this.modelValue['meta'] : {}
                    this.showAdd = false
                    this.imageStatus = 'finished'
                    this.uploadPercentage = 100
                    this.$emit('file-link', this.fileLink)
                }
            },
            getUrl(value) {
                if (this.modelValue) {
                    return '/' + this.modelValue
                } else {
                    return URL.createObjectURL(value)
                }
            },
            isImg() {
                let is = false
                let image = this.accept.split('/')
                is = image[0] === 'image'
                if (!is) {
                    const p = this.fileLink.split('.')
                    is = ['jpg', 'jpeg', 'png', 'gif'].includes(p[p.length - 1])
                }
                return is
            },
            emit() {
                if (this.meta) {
                    this.$emit('file-link', {'url': this.fileLink, 'meta': this.metaList})
                } else {
                    this.$emit('file-link', this.fileLink)
                }
                this.$emit('select', true)
            }

        }
    }
</script>

<style lang="scss">
    @import "../../../style/include";

    .#{$prefix}file-uploader {
        .image-card {
            position: relative;
            width: auto;
            min-width: 150px;
            min-height: 200px;
            @include dark() {
                background-color: #3a3e3a;
            }
            @include light() {
                background-color: white;
            }

            .file-meta {
                width: 250px;
            }

            .fails {
                -webkit-filter: grayscale(100%);
                filter: grayscale(100%);
            }

            .inProgress {
                -webkit-filter: blur(2px);
                filter: blur(2px);
            }

            .finished {
                -webkit-filter: blur(0px);
                filter: blur(0px);
            }

            .file {
                left: 0;
                right: 0;
                position: absolute;
                top: 0;
                bottom: 0;
            }

            .image {
                width: auto;
                min-width: 150px;
                height: 200px;
                z-index: -2;
                opacity: 0.8;
            }

            .image-icon {
                top: 70px;
                left: 0;
                right: 0;
                margin-left: auto;
                margin-right: auto;
                position: absolute;
                cursor: pointer;
            }

            .image-canvas {
                width: auto;
                min-width: 150px;
                height: 200px;
                position: relative;
                background-image: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
            }

            .image-close {
                cursor: pointer;
                position: absolute;
                left: 5px;
                top: 5px;
                z-index: 1;
            }

            .image-progress {
                z-index: 1;
                position: absolute;
                right: 5px;
                top: 5px;
                color: white;
            }

            .image-copy {
                z-index: 1;
                position: absolute;
                left: 5px;
                bottom: 5px;
            }
          .image-eye {
                z-index: 1;
                position: absolute;
                left: 50px;
                bottom: 5px;
            }
        }
    }
</style>
