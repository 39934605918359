<template>
    <div :class="$r.prefix+'search-container'">
        <div class="search-input" v-click-outside="handleclose">
            <r-input :active="active"
                     icon="mdi-magnify"
                     v-bind="$attrs"
                     :class="{'z-important':open}"
                     :modelValue="lazyValue"
                     :input-control-class="[inputControlClass,{'search-active-border':open}]">
                <input :autofocus="autofocus"
                       :type="type"
                       @focusin="active=true"
                       @focusout="active=false"
                       @input="handle"
                       :value="lazyValue"
                       autocomplete="no"
                       ref="input"
                />
                <r-progress-liner v-if="loading" color="color-two" class="w-full"></r-progress-liner>
            </r-input>
            <r-card v-if="open&&(list.length>0||!loading)"
                    class="card-select  z-important"
                    :class="{'card-tile':$attrs.tile!==undefined&&$attrs.tile!==false}"
            >
                <r-list v-if="list.length>0"
                        :items="list"
                        @update:modelValue="listInput">
                    <template v-slot="props">
                        <slot :item="props.item">
                            <div class="list-title">{{props.item['name']}}</div>
                        </slot>
                    </template>
                </r-list>
                <div v-else-if="!loading" class="py-5">
                    {{notFoundMsg}}
                </div>
            </r-card>
        </div>
        <transition name="fade" v-if="!noOverlay">
            <div v-if="open" class="search-shadow"></div>
        </transition>
    </div>
</template>
<script>
    import clickOutside from '../../directive/clickOutSide/index'

    export default {
        name: 'r-search-input',
        inheritAttrs: false,
        directives: {
            'click-outside': clickOutside
        },
        props: {
            notFoundMsg: {
                type: String,
                default: "Can't Find Anything :("
            },
            type: {
                type: String,
                default: 'text'
            },
            url: String,
            query: {
                type: String,
                default: 'search'
            },
            inputControlClass: [String, Object, Array],
            modelValue: [String, Number],
            autofocus: Boolean,
            noOverlay: Boolean
        },

        data() {
            return {
                lazyValue: this.modelValue,
                loading: false,
                active: false,
                open: false,
                idSet: null,
                list: []
            }
        },
        watch: {
            modelValue() {
                this.lazyValue = this.modelValue
            }
        },
        methods: {
            handleclose() {
                this.open = false
            },
            get() {
                if (this.url) {
                    this.loading = true
                    let link = this.url
                    link += (this.url.indexOf('?') > -1) ? '&' : '?'
                    link += this.query + '=' + this.lazyValue
                    this.$axios.get(link).then(({data}) => {
                        this.list = data
                        this.loading = false
                        this.open = true
                    }, (e) => {
                        this.loading = false
                    })
                }
            },
            handle(e) {
                this.lazyValue = e.target.value
                this.open = true
                this.loading = true
                clearTimeout(this.idSet)
                this.idSet = setTimeout(() => {
                    this.$emit('update:modelValue', this.lazyValue)
                    this.get()
                }, 1000)
            },
            listInput(e) {
                this.$emit('select', e)
                this.open = false
            }
        }
    }

</script>
<style lang="scss">
    @import '../../style/include';

    .#{$prefix}search-container {
        position: relative;

        .search-input {
            .sheet {
                transition: 0.5s all ease;
            }

            .card-select {
                position: absolute;
                left: 0;
                width: 100%;
                overflow-y: auto;
                max-height: 300px;

                &:not(.card-tile) {
                    border-radius: 0 0 20px 20px;
                }
            }

            .search-active-border:not(.input-tile) {
                border-radius: 20px 20px 0 0;
            }
        }

        .search-shadow {
            position: fixed;
            width: 100vw;
            height: 100vh;
            z-index: map_get($z-index, 'medium');
            top: 0;
            left: 0;
            backdrop-filter: blur(3px) grayscale(30%);
        }
    }
</style>
