<template>
    <r-input class="mt-5" :model-value="modelValue" hide labelControlClass="label-active" :class="`${$r.prefix}color-picker`">
        <input :value="color" type="color" @input="newColor">
        <div class="w-full">
            <r-range :model-value="alpha" :step="0.1" :min="0" :max="1" @update:model-value="newAlpha"></r-range>
        </div>
    </r-input>
</template>
<script>
    export default {
        name: 'r-color-picker',
        props: {
            modelValue: String
        },
        data() {
            return {
                color: '#000000',
                alpha: 1
            }
        },
        watch:{
            'modelValue':{
                handler(){
                    this.parse()
                },
                immediate:true
            }
        },
        methods: {
            parse() {
                if (!this.modelValue) {
                    return
                }
                let rgb = this.modelValue.replace(/\s/g, '').match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i)

                if (!rgb) {
                    this.color = this.modelValue
                    return
                }
                let alpha = rgb[4]
                let hex = rgb ?
                    (rgb[1] | 1 << 8).toString(16).slice(1) +
                    (rgb[2] | 1 << 8).toString(16).slice(1) +
                    (rgb[3] | 1 << 8).toString(16).slice(1) : this.modelValue;
                this.color = '#' + hex
                this.alpha = parseFloat(alpha)
                if(isNaN(this.alpha)){
                    this.alpha=1
                }
            },
            newColor(e) {
                this.color = e.target.value
                const rgbaCol = 'rgba(' + parseInt(this.color.slice(-6, -4), 16) + ',' + parseInt(this.color.slice(-4, -2), 16) + ',' + parseInt(this.color.slice(-2), 16) + ',' + this.alpha + ')';
                this.$emit('update:modelValue', rgbaCol)
            },
            newAlpha(e) {
                this.alpha = e
                const rgbaCol = 'rgba(' + parseInt(this.color.slice(-6, -4), 16) + ',' + parseInt(this.color.slice(-4, -2), 16) + ',' + parseInt(this.color.slice(-2), 16) + ',' + e + ')';
                this.$emit('update:modelValue', rgbaCol)
            }
        }
    }
</script>
<style lang="scss">
    @import "../../../style/include";

    .#{$prefix}color-picker {
        input {
            height: 30px;
            max-height: 30px;
            padding: 0;
        }
    }
</style>